import { Row, Col, Container, Nav, Tab } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import colorSharp2 from "../assets/img/color-sharp2.png";

import htctw from "../assets/projects/exp-1.PNG";
import changeathon from "../assets/projects/exp-2.png";
import macchangers from "../assets/projects/exp-3.png";
import mec23 from "../assets/projects/exp-4.png";
import xtreme from "../assets/projects/exp-5.png";
import engambassador from "../assets/projects/exp-6.png";
import git from "../assets/projects/exp-7.png";
import ieee from "../assets/projects/IEEEAppreciation.png";
import thmaoc from "../assets/projects/thmaoc.PNG";

import adaptation from "../assets/projects/art-1.png";
import nurture from "../assets/projects/art-2.png";
import art3 from "../assets/projects/art-3.png";
import heart from "../assets/projects/art-4.png";
import art5 from "../assets/projects/art-5.png";
import art6 from "../assets/projects/art-6.png";
import game from "../assets/projects/art-7.gif";

import website from "../assets/projects/website.png";
import slingpuck from "../assets/projects/hack-1.png";
import lyrify from "../assets/projects/hack-2.png";
import sprout from "../assets/projects/hack-3.gif";
import aeroscents from "../assets/projects/hack-4.png";
import lidar from "../assets/projects/hack-5.png";

import TrackVisibility from 'react-on-screen';
import "animate.css";


export const Projects = () => {
    
    const hackathons = [
        {
            title: "Personal Website",
            description: "Developed and deployed a responsive and interactive portfolio website using React and Bootstrap for the front-end and NodeJS for the back-end, hosted on Heroku. Implemented a custom AWS Lambda function integrated with API Gateway to dynamically serve fun facts.",
            imgUrl: website
        },
        {
            title: "Aeroscents",
            description: "Custom drone built with an Arduino UNO, HC-05 Bluetooth, ToF sensors, and a scent-dispensing system, paired with a React-based web app utilizing the Mappedin API for mapping, manual control, and future autonomous navigation.",
            imgUrl: aeroscents,
            projUrl: 'https://devpost.com/software/airoscents',
        },
        {
            title: "Lyrify",
            description: "Flutter-based app that integrates the Spotify API and Google Translate API to display song lyrics and translations, enabling users to learn languages through their favorite music.",
            imgUrl: lyrify,
            projUrl:'https://devpost.com/software/lyrify',
        },
        {
            title: "LIDAR Spatial Mapper",
            description: "3D modelling device that uses the MSP-ESP432E401Y microcontroller and TOF sensor. Configured UART communication between microcontroller and host computer in C. Wrote Python script to model data using Open3D, pySerial, Matplotlib, and NumPy.",
            imgUrl: lidar,
            projUrl: 'https://drive.google.com/file/d/1buQwBMmTcsqxhXaRYjQZbr5D93evtGND',
        },
        {
            title: "Sling Puck",
            description: "Unity game featuring custom physics for puck collisions, a sling mechanism using Line Renderers and dynamic 3D vector mapping, and a shop system for purchasing in-game skins, all implemented with C# scripts and textured 3D models.",
            imgUrl: slingpuck,
            projUrl: 'https://devpost.com/software/sling-puck',
        },
        {
            title: "Sprout",
            description: "Android app built with Android Studio that integrates an Arduino UNO, a soil moisture sensor, and a water level sensor to deliver real-time updates and insights about your plant's health.",
            imgUrl: sprout,
            projUrl:'https://devpost.com/software/sprout-mzrabh',
        }
        
    ];
    const experiential = [
        {
            title: "Advent of Cyber 2024",
            description: "Awarded for completing 24 cyber security challenges, proving an understanding of cyber security fundamentals and showing consistency, tenacity & continuous learning.",
            imgUrl: thmaoc,
        },
        {
            title: "IEEE",
            description: "Awarded for my sustained contribution to the Hamilton Section as Chair of the McMaster University Student Branch.",
            imgUrl: ieee,
        },
        {
            title: "IEEEXtreme Ambassador",
            description: "Volunteered as a Section Ambassador Lead in the IEEEXtreme 17.0 programming competition that hosted +17,000 participants.",
            imgUrl: xtreme,
        },
        {
            title: "MEC 2023",
            description: "AI-driven emergency response system that uses OpenAI API to process audio calls and generate reports to improve decision-making and response times.",
            imgUrl: mec23,
        },
        {
            title: "The Honeycomb Home",
            description: "Sustainable and modular housing solution to reduce overcrowding in Northern Manitoban Cree Indigenous communities, promoting privacy and minimizing the spread of Covid-19. Won 'Most Creative' in Cohort 1: Sustainable Cities in North Manitoba.",
            imgUrl: htctw,
            projUrl: 'https://drive.google.com/drive/u/1/folders/1SHToMOQHLdAHasTU17hre2_xqRjSe-IK',
        },
        {
            title: "Change-a-Thon",
            description: "Proposed web app to enhance digital literacy among seniors in long-term care homes, offering tutorials and live support from McMaster student volunteers.",
            imgUrl: changeathon,
            projUrl: 'https://www.macvideo.ca/media/D10+Tech+Talks+Solution+Proposal/1_ihfgsssu',
        },
        {
            title: "MacChangers",
            description: "Project to incentivize the Hamilton community to improve the aesthetic and atmosphere of long-term construction spaces. By involving local residents and businesses, the goal is to revitalize these areas, boost local commerce, and enhance the quality of life for Hamilton residents.",
            imgUrl: macchangers,
            projUrl: 'https://www.youtube.com/watch?v=KyhoTicZ0u8',
        },
        {
            title: "MacEng Ambassador",
            description: "Awarded for my commitment in supporting prospective students and being involved in recruitment initiatives.",
            imgUrl: engambassador,
        },
        {
            title: "Git Essential Training Basics",
            imgUrl: git,
        }
    ];
    const art = [
        {
            title: "Adaptation",
            description: "Wooden carving of a creature's skull, combining sharp, predatory features with contrasting prey-like traits. Reminiscent of a hunter’s taxidermy mount.",
            imgUrl: adaptation,
        },
        {
            title: "Nurture",
            description: "A piece that honors the sacrifice of motherhood, using wooden scraps, wire, and cloth to craft a poignant symbol of love and resilience.",
            imgUrl: nurture,
        },
        {
            title: "Wooden Heart",
            description: "Anatomically correct heart carved from oak, inspired by a family keepsake.",
            imgUrl: heart,
        },
        {
            title: "Astronaut Game",
            description: "2D platformer in Java featuring an astronaut collecting stars, with all artwork, including character and environment assets, designed and animated by me.",
            imgUrl: game,
        },

    ];
    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                    <TrackVisibility>
                        {({ isVisible }) => 
                            <div className={isVisible ? "animate__animated animate__bounce" : ""}>
                        <h2>Projects</h2>
                        <p>I love a good challenge.</p>
                        </div>}
                    </TrackVisibility>


                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Tech</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Honours and Awards</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Art</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            hackathons.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />

                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {
                                            experiential.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />

                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Row>
                                        {
                                            art.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />

                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
                <img className='background-image-right' src={colorSharp2} />
            </Container>
        </section>
    )
}